/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.bundle.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/jquery.cookie@1.4.1/jquery.cookie.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
